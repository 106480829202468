import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/user/model/filters', config);
}

const store = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/user/model/filters', formData);
}

const show = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/user/model/filters/'+id);
}

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/user/model/filters/'+id, formData);
}

const remove = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/user/model/filters/'+id);
}

export default {
    getAll,
    store,
    show,
    update,
    remove
}
